
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/modules/user'
import { isWxBrowser } from '@/utils/common'
import { ElMessage } from 'element-plus'


export const useFile = () => {

  const userStore = useUserStore()
  const { wechatShareLink, qrCodeSize: codeSize } = storeToRefs(userStore)

  // 保存二维码提示文字
  const saveQrCodeTips = computed(() => isWxBrowser() ? '长按二维码保存' : '保存二维码')

  // 生成二维码的尺寸
  const qrCodeSize = computed(() => codeSize.value)

  // 保存二维码
  const saveQrCode = (qrCodeImg: any, data: any) => {
  // 微信里打开 提示长按二维码保存图片
    if (isWxBrowser()) return ElMessage({ message: '长按二维码保存' })
    const a = document.createElement('a');
    a.href = qrCodeImg;
    a.download = `${data.title}.png`;
    a.click();
  }

  // 微信分享链接
  const shareLink = () => {
    if (wechatShareLink.value) {
      window.open(wechatShareLink.value, '_blank')
    } else {
      userStore.getWechatShareLink()
        .then(() => {
          window.open(wechatShareLink.value, '_blank')
        })
    }
  }

  return { saveQrCode, shareLink, saveQrCodeTips, qrCodeSize }
}