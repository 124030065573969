<script setup lang="ts">
import {computed, defineAsyncComponent, ref} from "vue";
import {Setting} from "@element-plus/icons-vue";
import NoticeList from "@/views/member/notice/components/NoticeList.vue";
import Settings from "@/views/member/notice/components/Settings.vue";
import {queryMessageListApi, queryUnreadRemindApi} from "@/api/message";

// 引入组件
const MainTitle = defineAsyncComponent(() => import("@/views/member/components/MainTitle.vue"));

/**
 * 当前选中选项卡
 */
const activeName = ref('system');

/**
 * 选项卡列表
 */
const tabList = ref([
  {tab: 'system', name: '系统公告', tips: '系统公告（网站管理员后台发布）', id: '1', read: 0, tabId: 'xitong'},
  {tab: 'file', name: '文件提醒', tips: '文件提醒（包含操作记录，到期前提醒，到期提醒，访客提醒等提醒）', id: '3', read: 0, tabId: 'file'},
  {tab: 'account', name: '账号提醒', tips: '账户提醒（包含开通、续费、即将到期、已到期等提醒）', id: '2', read: 0, tabId: 'zhanghu'},
]);

/**
 * 设置弹窗展示状态
 */
const settingsVisible = ref(false);

/**
 * 显示设置弹窗
 */
const showSettings = () => {
  settingsVisible.value = true;
};

/**
 * 设置弹窗关闭
 */
const handleSettingsClose = () => {
  settingsVisible.value = false;
}

/**
 * 判断是否有未读消息
 */
const hasUnreadMessage = async () => {
  let data:any = await queryUnreadRemindApi()
  for (let dataKey in data) {
    let item:any = tabList.value.find(item => item.tabId === dataKey)
    item.read = data[dataKey] || 0
  }
}
hasUnreadMessage();

/**
 * 获取选中选项卡的id
 */
const getActiveTabId = computed(() => {
  return tabList.value.find(item => item.tab === activeName.value)?.id;
})
</script>

<template>
  <div id="notice" class="form-view">
    <main-title>消息中心</main-title>

    <div class="tabs-wrap">
      <el-button link :icon="Setting" class="setting-btn" @click="showSettings">消息设置</el-button>

      <el-tabs v-model="activeName">
        <el-tab-pane v-for="item in tabList" :key="item.tab" :name="item.tab">
          <template #label>
            <el-badge is-dot class="item" :hidden="item.read === 0">
              {{item.name}}
            </el-badge>
          </template>

          <notice-list v-if="activeName === item.tab" :tab-name="getActiveTabId" />
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog v-model="settingsVisible" title="消息设置" width="920px">
      <settings v-if="settingsVisible" :tab-list="tabList" @close="handleSettingsClose" />
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
:deep(.el-badge__content.is-fixed.is-dot){
  right: 0;
}
:deep(.el-tabs){
  margin-top: 0.34rem;
  height: calc(100% - 0.5rem - 0.34rem);
}
:deep(.el-tabs__content){
  height: 100%;
  overflow: auto;
}
:deep(.el-tab-pane){
  height: 100%;
}
#notice{
  height: 100%;
  :deep(.main-title){
    border-bottom: none;
  }
  .tabs-wrap{
    position: relative;
    height: calc(100% - 0.55rem);
    .setting-btn{
      position: absolute;
      right: 0;
      line-height: 0.4rem;
      z-index: 9;
      height: 0.4rem;
      box-sizing: border-box;
      font-size: 0.16rem;
      color: #0B167D;
      :deep(.el-icon){
        color: #555;
        font-size: 0.21rem;
      }
    }
  }
}
</style>
