<script setup lang="ts">
import { onMounted, defineAsyncComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStoreHook } from "@/store/modules/user";
import checkPc from "@/utils/checkPc";

const userStore = useUserStoreHook()
const { userInfo } = storeToRefs(userStore)

// 定义组件
const Layout = defineAsyncComponent(() => import('@/layout/index.vue'));
const JoinPc = defineAsyncComponent(() => import('./components/JoinPc.vue'));
const JoinPhone = defineAsyncComponent(() => import('./components/JoinPhone.vue'));

const isPC = checkPc()
</script>

<template>
  <layout :footer="false" :parent="false">
    <div class="pc-wrap" v-if="isPC">
      <join-pc :userInfo="userInfo" />
    </div>
    <div class="phone-wrap" v-else>
      <join-phone :userInfo="userInfo" />
    </div>
  </layout>
</template>

<style scoped lang="scss">
.phone-wrap {
  padding-bottom: 0.6rem;
}
</style>
