<script lang="ts" setup>
import {computed, defineAsyncComponent, ref} from 'vue';
import {useUser} from "@/composable/useUser";
import {vipConsumptionRecordApi, vipPrivilegeApi} from "@/api/member";

// 引入组件
const MainTitle = defineAsyncComponent(() => import("@/views/member/components/MainTitle.vue"));
const VipCompare = defineAsyncComponent(() => import("@/components/vipCompare/index.vue"));

const {userInfo, handleShowText} = useUser();

/**
 * 是否是会员
 */
const isVip = computed(() => {
  return +userInfo.value.vip === 1;
})

/**
 * vip会员类型
 */
const vipType = computed(() => {
  return isVip.value ? '个人会员' : '未开通';
});

/**
 * vip会员开通状态
 */
const vipStatus = computed(() => {
  return isVip.value ? '您已开通': '您未开通';
});

/**
 * vip会员交易记录
 */
const vipTradeRecord = ref<any>([]);

/**
 * 获取vip消费记录
 */
const getVipTradeRecord = async () => {
  vipTradeRecord.value = await vipConsumptionRecordApi();
  console.log(vipTradeRecord.value);
}

getVipTradeRecord();

/**
 * vip会员特权
 */
const vipPrivilege = ref<any>([]);

/**
 * 获取vip会员特权
 */
const getVipPrivilege = async () => {
  vipPrivilege.value = await vipPrivilegeApi();
}
getVipPrivilege();
</script>

<template>
  <div id="member" class="form-view">
    <main-title>会员中心</main-title>

    <div class="card">
      <div class="user-info" :style="`background-image: url(${require('@/assets/member/img/member/bg1.png')})`">
        <div class="wrap">
          <div class="vip-info">
            VIP会员：{{ handleShowText(1).vipType }}
          </div>
          <div class="vip-status">
            <!-- <span>{{ vipStatus }}</span> &nbsp; -->
            <!-- <span v-if="isVip">{{ handleShowText(2).timeText }}</span> &nbsp; -->
            <span>{{ handleShowText(1).timeText }}</span> &nbsp;
            <router-link to="/member/join">
              <el-button class="renewal">{{ handleShowText(1).vipText }}</el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="order-list" v-if="isVip || userInfo.yxtime">
        <h2 class="title">VIP订单获取记录</h2>
        <table cellspacing="1">
          <thead>
            <tr>
              <th>交易类型</th>
              <th>交易时间</th>
              <th>会员时长</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in vipTradeRecord" :key="order.id">
              <td>{{ order.title }}</td>
              <td>{{ order.create_time }}</td>
              <td>{{ order.num }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="privilege" v-if="isVip">
        <h2 class="title">您拥有的VIP特权</h2>

        <div class="content">
          <div class="list-wrap">
            <div class="list-item" v-for="(item) in vipPrivilege" :key="item.id">{{ item.title }}</div>
          </div>
        </div>
      </div>

      <div class="compare">
        <h2 class="title">VIP权益详细对比</h2>

        <vip-compare />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#member {
  height: 100%;
  overflow: auto;

  :deep(.main-title) {
    border-bottom: none;
  }

  * {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
  }

  .card {
    background-color: #fff;
    padding: 0.34rem 0.51rem;
    margin-top: 0.09rem;

    table {
      background-color: #666;
      width: 100%;
      margin-top: 0.24rem;
      th,
      td {
        text-align: center;
        height: 0.6rem;
        font-size: 0.16rem;
        color: #313131;
      }
      th {
        background-color: #F2F2F2;
        font-size: 0.18rem;
      }
      td {
        background-color: #fff;
      }
    }

    .user-info {
      width: 100%;
      aspect-ratio: 1387/213;
      position: relative;

      .wrap {
        position: absolute;
        top: 0;
        bottom: 0;
        left: .5rem;
        margin: auto;
        height: max-content;
        transform: translateY(-.2rem);

        .vip-info {
          font-size: 0.24rem;
          color: #FEF3E4;

          background: linear-gradient(235deg, #FEF3E4 0%, #FDD9B5 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .vip-status {
          font-size: 0.12rem;
          // color: rgba(254, 243, 228, 0.26);
          color: #dfdfdf;
          margin-top: 0.11rem;
          display: flex;
          align-items: center;

          .renewal {
            width: 0.56rem;
            height: 0.19rem;
            background: linear-gradient(235deg, #FEF3E4, #FDD9B5);
            border-radius: 0.09rem;
            text-align: center;
            line-height: 0.19rem;
            font-size: 0.14rem;
            color: #2B2D34;
            margin-left: 0.1rem;
            padding: 0;
          }
        }
      }
    }

    h2.title {
      font-size: 0.24rem;
      font-weight: bold;
      color: #313131;
    }

    .order-list {
      margin-top: 0.38rem;
    }

    .privilege {
      margin-top: 0.53rem;

      .content {
        width: 100%;
        background: #F2F2F2;
        margin-top: 0.42rem;
        padding: 0.48rem 0.77rem;

        .list-wrap {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-row-gap: 0.3rem;

          .list-item {
            display: flex;
            align-items: center;
            font-size: 0.18rem;
            color: #1C1C1C;

            &::before {
              content: '';
              display: block;
              width: 0.18rem;
              height: 0.18rem;
              background: #FF978B;
              border-radius: 50%;
              margin-right: 0.16rem;
            }
          }
        }
      }
    }

    .compare {
      margin-top: 0.76rem
    }
  }
}
</style>
