<script setup lang="ts">
import {defineAsyncComponent, ref, watch} from 'vue'
import { fileTrackApi } from '@/api/file'
import {useRoute} from "vue-router";
import { useAddress } from '@/utils/getAddress';
import Tips from '@/components/Tips/Tips.vue';

const TrackItem = defineAsyncComponent(() => import("@/views/member/file/components/phone/TrackItem.vue"));

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const route = useRoute();

/**
 * 文件列表处理
 */
const tableData = ref<any>([])
const tableParams = ref({ id: '', limit: 10, page: 1, title: '' })
const tableTotal = ref(0)
// 获取文件列表
const getFileList = async () => {
  const res: any = await fileTrackApi(tableParams.value)
  // console.log(res)
  tableData.value = res.data
  tableTotal.value = res.total
}

watch(
  () => props.data,
  () => {
    if (props.data && props.data.id) {
      tableParams.value.id = props.data.id
    }else{
      tableParams.value.id = '' + route.query.id
    }
    if (tableParams.value.id){
      getFileList()
    }
  },
  { deep: true, immediate: true }
)

console.log(route.query.id, 'route');

const addressInfo = useAddress();
const {showAddress} = addressInfo;
</script>

<template>
  <!-- 表格 -->
  <div class="track-wrap">
    <div class="table-box pc-wrap">
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        style="width: 100%;"
        height="440px"
        size="default"
        border
      >
        <el-table-column prop="createtime" label="时间" width="180" align="center" show-overflow-tooltip />
        <el-table-column prop="nickname" label="用户" align="center" show-overflow-tooltip />
        <el-table-column prop="num" label="第几次浏览" width="80" align="center" show-overflow-tooltip />
        <el-table-column prop="address" label="地区" align="center" show-overflow-tooltip>
          <template #default="{row}">
            <span v-if="+row.address_button === 1">{{row.address}}</span>
            <template v-else>
              <el-button v-if="!row.addressStr" type="primary" link @click="showAddress(row, 2, getFileList)">点击查看</el-button>
              <span v-else>{{row.addressStr}}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="ip" label="IP" width="150" align="center" show-overflow-tooltip />
        <el-table-column prop="shebei" label="访问设备" width="80" align="center" />
        <el-table-column prop="pingtai" label="访问平台" align="center" show-overflow-tooltip />
        <el-table-column prop="file_name" label="访问文档" align="center" show-overflow-tooltip />
        <el-table-column prop="timenum" label="停留时长" align="center" show-overflow-tooltip />
        <el-table-column prop="plan" label="浏览进度" width="80" align="center" show-overflow-tooltip />
      </el-table>
      <div v-if="tableTotal > 0" class="flex-c-center pt20 pb20">
        <el-pagination background layout="total, prev, pager, next, jumper" v-model:current-page="tableParams.page"
          v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
      </div>
    </div>
    <!--移动端浏览轨迹页面-->
    <div class="phone-wrap" style="height: calc(100% - 0.98rem)">
      <div style="text-align: right; padding-top: 0.2rem;">
        <Tips />
      </div>

      <div class="track phone-wrap">
        <template v-if="tableData.length">
          <track-item v-for="(item, index) in tableData" :key="index" :data="item" @showAddress="showAddress(item, 2, getFileList)" />
        </template>
        <el-empty v-else />
      </div>
      <div class="pagination">
        <el-pagination background layout="prev, next"  v-model:current-page="tableParams.page"
          v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.track-wrap{
  height: 100%;

  .phone-wrap{
    height: 100%;

    .track{
      padding: 0.28rem 0.52rem;
      overflow: auto;
      height: 90%;
    }

    .pagination{
      margin-top: 0.4rem;

      :deep(.el-pagination){
        justify-content: center;
      }
    }
  }
}
</style>
