<script setup lang="ts">
import { ref, defineAsyncComponent, onMounted } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { Delete, RefreshLeft } from "@element-plus/icons-vue";
import { fileRecycleApi, fileRecycleDelApi, fileRecycleRestoreApi } from '@/api/file'
import checkPc from '@/utils/checkPc'

/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 引入组件
const MainTitle = defineAsyncComponent(() => import("@/views/member/components/MainTitle.vue"));
const PhoneListItem = defineAsyncComponent(() => import("@/views/member/recycle/PhoneListItem.vue"));

/**
 * 筛选表单
 */
const form = ref<any>({
  title: ''
})

/**
 * 文件列表处理
 */
const tableData = ref<any>([])
const tableParams = ref({ limit: 15, page: 1, title: '' })
const tableTotal = ref(0)
// 获取文件列表
const getFileList = async () => {
  const res: any = await fileRecycleApi(tableParams.value)
  // console.log(res)
  tableData.value = res.data
  tableTotal.value = res.total
}

/* 表格数据多选处理 */
const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val: any) => {
  multipleSelection.value = val
}

//  提示文字处理
const toTips = (type: string) => {
  let tips = '', api = <any>null
  switch (type) {
    case 'del':
      tips = '删除'
      api = fileRecycleDelApi
      break;
    case 'restore':
      tips = '恢复'
      api = fileRecycleRestoreApi
      break;
  }
  return { tips, api }
}

// 批量删除、恢复
const delBtn = (type: string) => {
  let data = multipleSelection.value
  let tips = toTips(type).tips
  if (data.length == 0) {
    ElMessage.error(`请选择需要${tips}的数据`)
  } else {
    let ids = data.map((item: any) => item.id)
    // console.log(ids)
    toDelete(type, ids.toString())
  }
}

// 删除接口
const toDelete = async (type: string, ids: any, showConfirm: Boolean = true) => {
  let { tips, api } = toTips(type)
  try {
    if (showConfirm) {
      await ElMessageBox.confirm(`该操作会${tips}当前数据`, `确定要${tips}数据吗?`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
    }
    await api({ id: ids })
    ElMessage.success(`${tips}成功`)
    getFileList()
  } catch (error) {

  }
}

// 手机端删除、恢复
const toDeleteM = (e: any) => {
  toDelete(e.type, e.id, false)
}

/**
 * 用户下拉
 */
const userOptions = ref<any[]>([]);

onMounted(() => {
  getFileList()
})
</script>

<template>
  <div id="recycle">
    <div v-if="isPC" class="form-view pc-wrap">
      <main-title>回收站</main-title>
      <div class="table-top pt10 pb10">
        <el-form inline :model="form">
          <el-form-item>
            <el-button color="#717171" @click="delBtn('del')">批量删除</el-button>
          </el-form-item>
          <el-form-item>
            <el-button color="#FF7B6D" @click="delBtn('restore')">批量恢复</el-button>
          </el-form-item>
          <el-form-item>
            <div class="flex-a-center">
              <el-input v-model="tableParams.title" placeholder="请输入文件标题" />
              <el-button color="#FF7B6D" @click="tableParams.page = 1; getFileList()">搜索</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table-box">
        <el-table ref="multipleTableRef" :data="tableData" style="width: 100%" height="calc(100vh - 0.84rem - 200px)"
          size="default" @selection-change="handleSelectionChange">
          <el-table-column type="selection" :width="55" />
          <el-table-column prop="title" label="文档名称" align="center" show-overflow-tooltip />
          <el-table-column prop="id" label="文档ID" align="center" />
          <el-table-column prop="deletetime" label="删除时间" :width="200" align="center" />
          <el-table-column prop="recycle_text" label="失效时间" align="center" />
          <el-table-column prop="action" label="操作" align="center" :width="200">
            <template #default="scope">
              <el-button link :icon="Delete" @click="toDelete('del', scope.row.id)">
                <span>删除</span>
              </el-button>
              <el-button link :icon="RefreshLeft" @click="toDelete('restore', scope.row.id)">
                <span>还原</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableTotal > 0" class="flex-c-center pt20 pb20">
          <el-pagination background layout="total, prev, pager, next, jumper" v-model:current-page="tableParams.page"
            v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
        </div>
      </div>
    </div>
    <div v-else class="phone-wrap flex-column-center">
      <div class="list-wrap">
        <phone-list-item v-for="item in tableData" :key="item.file_id" :data="item" @toDelete="toDeleteM" />
      </div>
      <div v-if="tableTotal > 0" class="flex-c-center pt20 pb20">
        <el-pagination :hide-on-single-page="true" background layout="prev, next" v-model:current-page="tableParams.page"
          v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#recycle {
  height: 100%;
  overflow: auto;

  .pc-wrap {
    :deep(.el-form--inline) {
      .el-form-item {
        margin-bottom: 0;
        margin-right: 10px;

        .el-input__wrapper {
          background-color: transparent;
        }
      }
    }

    .table-top {
      :deep(.el-button) {
        color: #fff;
      }
    }

    .table-box {
      :deep(.el-button.is-link) {
        color: #06a7ff;

        img {
          margin-right: 5px;
        }
      }
    }

    .pagination {
      margin-top: 30px;

      :deep(.el-pagination) {
        justify-content: center;
      }
    }
  }

  .phone-wrap {
    background: #fff;
    padding: 0.3rem;
    height: 100%;
    box-sizing: border-box;
    display: flex;

    .list-wrap {
      flex: 1;
      width: 100%;
      // margin-top: 0.38rem;
      // height: 76vh;
    }

    .pagination {
      margin-top: 0.2rem;

      :deep(.el-pagination) {
        justify-content: center;
      }
    }
  }
}
</style>

