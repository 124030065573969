<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="member-main flex">
    <!-- 左侧菜单 -->
    <div class="left-container">
      <!-- 头像 -->
      <div class="header-container flex-a-center">
        <div :class="['header', 'mr15', {'vip': userInfo.vip == 1}]">
          <img :src="userInfo.avatar" alt="">
        </div>
        <div class="user-box">
          <p class="user-name">{{userInfo.username}}</p>
        </div>
      </div>
      <!-- 菜单 -->
      <div class="menu-container">
        <div class="menu-box">
          <template v-for="(v, i) in menu"
            :key="i">
            <div
              v-if="!v.meta.hidden"
              :class="['menu-item', 'flex-a-center', 'cursor-pointer', { 'router-link-active': v.path == route.path }]"
              @click="toPath(v)"
            >
              <div v-if="v.meta.showDialog">
                <Customize placement="right" :offset="60">
                  <template #default>
                    <div class="left-box flex-a-center">
                      <img class="mr21" :src="require(`@/assets/member/${v.meta.icon}`)" alt="">
                      <p class="name">{{ v.meta.title }}</p>
                    </div>
                    <img v-if="v.meta.showArrow" class="right-icon" src="@/assets/member/right.png" alt="">
                  </template>
                </Customize>
              </div>
              <template v-else>
                <div class="left-box flex-a-center">
                  <img class="mr21" :src="require(`@/assets/member/${v.meta.icon}`)" alt="">
                  <p class="name">{{ v.meta.title }}</p>
                </div>
                <img v-if="v.meta.showArrow" class="right-icon" src="@/assets/member/right.png" alt="">
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="right-container">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStoreHook } from "@/store/modules/user";
import { useUser } from '@/composable/useUser'
import Customize from '@/components/customize/Customize.vue';

const { toPath } = useUser()

const userStore = useUserStoreHook()
const { userInfo } = storeToRefs(userStore)

const router = useRouter()
const route = useRoute()
const routes = router.getRoutes()

const menu = ref<any>([])

const filterRoutes = () => {
  let arr = routes.filter(item => item.name == 'member-index')
  if (arr.length) menu.value = arr[0].children
}

filterRoutes()

onMounted(() => {
})

</script>

<style lang="scss" scoped>
.member-main {
  height: 100%;
  background-color: #F6F8F9;
  .left-container {
    width: 2.87rem;
    height: 100%;
    .header-container {
      height: 1rem;
      padding: 0 0.46rem;
      border-bottom: #E4E8EB solid 0.01rem;
      .header {
        img {
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          object-fit: cover;
          display: block;
        }
        &.vip {
          position: relative;
          img {
            border: 0.03rem solid #FFC55B;
          }
          &::after {
            content: '';
            width: 0.25rem;
            height: 0.22rem;
            background: url('@/assets/header/u006.png') no-repeat;
            background-size: 100%;
            position: absolute;
            right: -0.1rem;
            bottom: -0.05rem;
          }
        }
      }
      .user-box {
        width: calc(100% - 0.4rem - 0.15rem);
        .user-name {
          font-size: 0.16rem;
          color: #2B2B2B;
        }
      }
    }
    .menu-container {
      .menu-box {
        margin: 0.27rem 0 0 0.28rem;
        .menu-item {
          height: 0.57rem;
          border-radius: 0.1rem;
          padding: 0 0.2rem 0 0.26rem;
          justify-content: space-between;
          margin-bottom: 0.1rem;
          .left-box {
            img {
              width: 0.3rem;
            }
            .name {
              font-size: 0.16rem;
              color: #2B2B2B;
            }
          }
          .right-icon {
            width: 0.08rem;
          }
          &.router-link-active {
            background: #EEEFF0;
          }
        }
      }
    }
  }
  .right-container {
    width: calc(100% - 2.87rem);
    height: 100%;
    overflow: hidden;
  }
}
@media screen and (max-width: 768px) {
  .left-container{
    display: none;
  }
  .member-main .right-container{
    width: 100%;
  }
}
</style>
