<template>
  <div class="app-main">
    <div class="pc-wrap" v-if="isPC">
      <HeaderApp />
      <div class="main-container">
        <router-view v-if="parent" />
        <slot v-else />
        <FooterApp v-if="showFooter()" />
      </div>
      <InviteView v-if="showInvite" />
      <AdvertView />
    </div>
    <div class="phone-wrap" v-else>
      <phone :parent="parent" :bg="bg" :showTitle="showTitle" :pageName="pageName" :show-back="showBack"
        :backCallback="backCallback" :showTopNav="showTopNav">
        <slot />
        <FooterApp v-if="showFooter()" />
      </phone>
      <!-- <InviteView v-if="showInvite" /> -->
      <AdvertView />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import checkPc from '@/utils/checkPc';
import { storeToRefs } from 'pinia'
import { useUserStoreHook } from "@/store/modules/user";

const route = useRoute()

const userStore = useUserStoreHook()
const { userInfo, inviteInfo } = storeToRefs(userStore)

// 引入组件
const HeaderApp = defineAsyncComponent(() => import('./HeaderApp.vue'));
const FooterApp = defineAsyncComponent(() => import('./FooterApp.vue'));
const Phone = defineAsyncComponent(() => import('./phone/index.vue'));
const InviteView = defineAsyncComponent(() => import('@/components/Invite/index.vue'));
const AdvertView = defineAsyncComponent(() => import('@/components/Advert/index.vue'));

const props = defineProps({
  parent: {
    type: Boolean,
    default: true
  },
  footer: {
    type: Boolean,
    default: true
  },

  //  移动端
  bg: {
    type: String,
    default: ''
  },
  showTitle: {
    type: Boolean,
    default: true
  },
  pageName: {
    type: String,
    default: ''
  },
  showBack: {
    type: Boolean,
    default: true
  },
  backCallback: {
    type: Function,
    default: undefined
  },
})

const showFooter = () => {
  return props.footer && route.meta.showFooter
}

const showTopNav = computed(() => {
  return !!route.meta.showTopNav
})

const showInvite = computed(() => {
  if (inviteInfo.value?.status == 1 && userInfo.value.token && (!route.path.includes('member') || route.path == '/member/join')) return true
  return false
})

/**
 * 判断是否PC端
 */
const isPC = checkPc();
</script>

<style lang="scss" scoped>
.app-main {
  .main-container {
    height: calc(100vh - 0.84rem);
    overflow: hidden auto;
  }
}
@media screen and (max-width: 768px){
  .app-main{
    height: 100%;
    .phone-wrap{
      height: 100%;
    }
  }
}
</style>
