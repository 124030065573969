import { storeToRefs } from 'pinia'
import { useUserStoreHook } from "@/store/modules/user";
import { timeStamp2String } from '@/utils/common'
import { ElMessageBox } from 'element-plus'
import router from '@/router'

export function useUser() {
  const userStore = useUserStoreHook()
  const { userInfo } = storeToRefs(userStore)

  // 校验权限
  const checkAuth = (vipAuth: boolean) => {
    return (vipAuth && userInfo.value.vip == 1) || !vipAuth;
  }

  // 校验权限 后台联动
  /**
   * 打开次数设置    open_times 1
   * 加密设置       encryption 1
   * 访客查看提醒    visitor_reminder
   * 查看访客轨迹    visitor_trajectory 1
   * 访问人数设置    visitors   1
   * 阅读时长设置    reading_duration  1
   * 有效期设置      validity_times  1
   * 极速上传下载    quickly_upload 1
   * 大文件上传      large_file 1
   * 批量上传文件    batch_upload 1
   * 文件搜索        file_search 1
   * 远程销毁        remote_destruction 1
   * 批量分享        batch_sharing  1
   * 无限上传次数    uploads_num 1
   * 无限上传文件页数    pages_num
   * 回收站功能          recycle 1
   * @param authStr  权限字符
   * @param needRefresh  更新用户信息
   */
  const checkAuthBack = async (authStr: string, needRefresh = false) => {
    if (needRefresh) {
      const res:any = await userStore.getUserInfo()
      let equity:any = res.equity_list
      // console.log(equity);
      return Promise.resolve(equity[authStr] == 1 ? true : false)
    } else {
      let user:any = userInfo.value || {}
      let equity:any = user.equity_list
      return Promise.resolve(equity[authStr] == 1 ? true : false)
    }
  }

  /**
   * 处理会员显示文字
   * @param type
   * @returns
  */
  const handleShowText = (type:number) => {
    let user = userInfo.value
    let obj = { timeText: '未开通', vipText: '开通', vipType: '未开通' }
    if (user.vip == 1) {
      if (type == 1) obj.timeText = `您已开通 ${timeStamp2String(user.yxtime, 'Y-m-d h:i:s')} 到期`, obj.vipType = '个人会员'
      if (type == 2) obj.timeText = `会员有效期至${timeStamp2String(user.yxtime, 'Y-m-d h:i:s')}`
      obj.vipText = '续费'
    } else {
      if (user.yxtime) {
        if (type == 1) obj.timeText = `您的会员已到期`, obj.vipType = '已到期'
        if (type == 2) obj.timeText = `您的会员已到期`
        obj.vipText = '续费'
      }
    }
    return obj
  }

  /**
   * 非会员操作会员功能提示
   * @param authStr 权限key
   * @param needRefresh 更新用户信息
   * @param tips 提示语
   * @returns {Promise}
  */
  const handleVipTip = async (authStr: string, needRefresh = false, tips = '') => {
    console.log(authStr)
    const isAuth = await checkAuthBack(authStr, needRefresh)
    console.log(isAuth, 'isAuth')
    if (!isAuth) {
      try {
        // 确认提示
        await ElMessageBox.confirm(`${tips ? tips : '此功能为会员功能，需要开通会员才能使用。'}`, '提示', {
          confirmButtonText: '去开通',
          cancelButtonText: '取消'
        })
        const route = router?.currentRoute.value
        router.push(`/member/join?redirect=${route.path}&params=${JSON.stringify(route.query ? route.query : route.params)}`)
      } catch (error) {
        console.log(error)
        return Promise.resolve(false)
      }
    } else {
      return Promise.resolve(true)
    }
  }

  // 路由跳转
  const toPath = async (v: any) => {
    let authStr = v.meta?.authStr
    if (authStr) {
      const status = await handleVipTip(authStr)
      if (!status) return
      router.push(v.path)
    } else {
      router.push(v.path)
    }
  }

	return { checkAuth, checkAuthBack, handleShowText, userInfo, handleVipTip, toPath }
}
