import { getFileOptionsAuth } from "@/api/member";
import { useNotVipTips } from "./notVipTips";
import { ElMessage } from "element-plus";
import { tr } from "element-plus/es/locale";

/**
 * @param {string} authField 需要验证的字段
 * 文件设置按钮  setbutton
 * 复制链接按钮  cobybutton
 * 文件重命名按钮   renamebutton
 * 文件替换按钮   replacebutton
 * 文件预览按钮   previewbutton
 * 文件下架提示按钮   hintbutton
 * 删除  remote_destruction
 * 文件上下架  unmountbutton
 */
export const useCheckFileOptionsAuth = () => {
  const notVipTipsInfo = useNotVipTips();
  const {notVipTips} = notVipTipsInfo;

  const checkFileOptionsAuth = async (authField: string) => {
    const res:any = await getFileOptionsAuth({type: authField});
    console.log(res, 'res')
    if(!res) return true;
    if(res.code){
      if (res.code === 400) {
          notVipTips(res.msg || res.message);
          return false;
        } else {
          ElMessage.error(res.msg || res.message);
          return false
        }
    }
  }
  return {checkFileOptionsAuth};
}