<template>
  <div id="file">
    <div v-if="isPC" class="form-view pc-wrap">
      <main-title>文件管理</main-title>
      <div class="table-top pt10 pb10">
        <el-form inline>
          <el-form-item>
            <el-button type="primary" @click="changeStatusBtn('up')">批量上架</el-button>
          </el-form-item>
          <el-form-item>
            <el-button color="#6F95D5" @click="changeStatusBtn('down')">批量下架</el-button>
          </el-form-item>
          <el-form-item>
            <el-button color="#717171" @click="delBtn">批量删除</el-button>
          </el-form-item>
          <el-form-item>
            <div class="flex-a-center">
              <el-input v-model="tableParams.title" placeholder="请输入标题" clearable />
              <el-button type="primary" @click="toSearch">搜索</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table-box" v-loading="loading">
        <el-table ref="multipleTableRef" :data="tableData" style="width: 100%;" height="calc(100vh - 0.84rem - 200px)"
          size="default" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="title" label="文档名称" align="center" show-overflow-tooltip />
          <el-table-column prop="id" label="文档ID" align="center" width="100">
            <template #default="{ row }">
              ID{{ row.id }}
            </template>
          </el-table-column>
          <el-table-column prop="createtime" label="创建时间" width="200" align="center" />
          <el-table-column prop="sctime" label="失效时间" align="center" width="200">
            <template #default="scope">
              {{ scope.row.sctime && scope.row.sctime != '0' ? formatTimeSelf(scope.row.sctime) : '永久' }}
            </template>
          </el-table-column>
          <el-table-column prop="pageview" label="浏览人数" align="center" width="120">
            <template #default="scope">
              <div class="flex-column-center">
                <div class="b-num">{{ scope.row.pageview }}</div>
                <div class="b-text cursor-pointer" @click="toVisitor(scope.row)">查看访客</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="viewcount" label="浏览次数" align="center" width="120">
            <template #default="scope">
              <div class="flex-column-center">
                <div class="b-num">{{ scope.row.viewcount }}</div>
                <div class="b-text cursor-pointer" @click="toTrack(scope.row)">查看轨迹</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="上下架" align="center" width="120">
            <template #default="scope">
              <el-switch :model-value="scope.row.status" active-value="1" inactive-value="0"
                style="--el-switch-on-color: #FF7B6D;" @click="changeStatus(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column prop="action" label="操作" align="center" width="150">
            <template #default="scope">
              <el-dropdown>
                <el-button color="#fff">
                  查看<el-icon class="el-icon--right">
                    <CaretBottom />
                  </el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="toSet(scope.row)">设置</el-dropdown-item>
                    <el-dropdown-item @click="toCopyLink(scope.row)">复制链接</el-dropdown-item>
                    <el-dropdown-item @click="toRename(scope.row)">重命名</el-dropdown-item>
                    <el-dropdown-item @click="toReplace(scope.row)">替换</el-dropdown-item>
                    <!-- <el-dropdown-item @click="toDownload(scope.row)">下载</el-dropdown-item> -->
                    <el-dropdown-item @click="toView(scope.row)">预览</el-dropdown-item>
                    <el-dropdown-item @click="toDownTips(scope.row)">下架提示</el-dropdown-item>
                    <el-dropdown-item @click="toDelete(scope.row.id)">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableTotal > 0" class="flex-c-center pt20 pb20">
          <el-pagination background layout="total, prev, pager, next, jumper" v-model:current-page="tableParams.page"
            v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
        </div>
      </div>
      <!-- 查看访客 -->
      <ViewVisitors ref="viewVisitorsRef"></ViewVisitors>
      <!-- 查看轨迹 -->
      <ViewTrack ref="viewTrackRef"></ViewTrack>
      <!-- 设置文件 -->
      <SetFile ref="setFileRef" @success="getFileList"></SetFile>
      <!-- 复制链接 -->
      <CopyLink ref="copyLinkRef"></CopyLink>
      <!-- 重命名文件 -->
      <FileRename ref="fileRenameRef" @success="getFileList"></FileRename>
      <!-- 替换 -->
      <Replace ref="replaceRef" @success="getFileList"></Replace>
      <!-- 下架提示语 -->
      <DownTips ref="downTipsRef" @success="getFileList"></DownTips>
      <!-- 下载 -->
      <el-dialog v-model="downloadVisible" title="下载" fullscreen>
        <iframe :src="downloadUrl" frameborder="0" style="width: 100%; height: 600px"></iframe>
      </el-dialog>
    </div>
    <phone-file v-else :data="tableData" @refresh="getFileList" @search="toSearch">
      <template #search>
        <el-input
            v-model="tableParams.title"
            placeholder="请输入标题"
            :prefix-icon="Search"
            clearable
            @change="toSearch"
        />
      </template>
      <template #pagination>
        <el-pagination :hide-on-single-page="true" background layout="prev, next" v-model:current-page="tableParams.page"
          v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
      </template>
    </phone-file>
  </div>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent, onMounted, computed } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {CaretBottom, Search} from '@element-plus/icons-vue'
import { fileListApi, fileDelApi, fileStatusApi } from '@/api/file'
import checkPc from '@/utils/checkPc'
import { useUser } from '@/composable/useUser'
import {formatTimeSelf} from "@/utils/formatDateTime";
import { useCheckFileOptionsAuth } from '@/utils/checkFileOptionsAuth'

const { checkAuth, handleVipTip } = useUser()

/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 引入组件
const MainTitle = defineAsyncComponent(() => import("@/views/member/components/MainTitle.vue"));
const SetFile = defineAsyncComponent(() => import("@/views/member/file/components/SetFile.vue"));
const ViewVisitors = defineAsyncComponent(() => import("@/views/member/file/components/ViewVisitors.vue"));
const ViewTrack = defineAsyncComponent(() => import("@/views/member/file/components/ViewTrack.vue"));
const FileRename = defineAsyncComponent(() => import("@/views/member/file/components/FileRename.vue"));
const Replace = defineAsyncComponent(() => import("@/views/member/file/components/Replace.vue"));
const CopyLink = defineAsyncComponent(() => import("@/views/member/file/components/CopyLink.vue"));
const DownTips = defineAsyncComponent(() => import("@/views/member/file/components/DownTips.vue"));
const PhoneFile = defineAsyncComponent(() => import("@/views/member/file/components/phone/PhoneFile.vue"));

const checkAuthInfo = useCheckFileOptionsAuth();
const {checkFileOptionsAuth} = checkAuthInfo;

// 设置
const setFileRef = ref()
const toSet = async (item: any) => {
  const status = await checkFileOptionsAuth('setbutton');
  if (!status) return
  setFileRef.value.openDialog(item)
}

// 查看访客
const viewVisitorsRef = ref()
const toVisitor = async (item: any) => {
  const status = await handleVipTip('visitor_trajectory')
  if (!status) return
  viewVisitorsRef.value.openDialog(item)
}

// 查看轨迹
const viewTrackRef = ref()
const toTrack = async (item: any) => {
  const status = await handleVipTip('visitor_trajectory')
  if (!status) return
  viewTrackRef.value.openDialog(item)
}

// 复制链接
const copyLinkRef = ref()
const toCopyLink = async (item: any) => {  
  const status = await checkFileOptionsAuth('cobybutton');
  if (!status) return
  copyLinkRef.value.openDialog(item)
}

// 重命名
const fileRenameRef = ref()
const toRename = async (item: any) => {
  const status = await checkFileOptionsAuth('renamebutton');
  if (!status) return
  fileRenameRef.value.openDialog(item)
}

// 替换
const replaceRef = ref();
const toReplace = async (item: any) => {
  const status = await checkFileOptionsAuth('replacebutton');
  if (!status) return
  replaceRef.value.openDialog(item)
}

// 下载地址
const toDownloadUrl = computed(() => {
  return process.env.VUE_APP_PROXY_DOMAIN + '/Filemanage/downfile'
});

const downloadUrl = ref<string>('')

const downloadVisible = ref<boolean>(false)

// 下载
const toDownload = (item: any) => {
  // window.open(`${toDownloadUrl.value}?url=${item.url}`, '_blank')
  // window.open(`${toDownloadUrl.value}?url=${item.url}`, '_blank')
  downloadVisible.value = true;
  downloadUrl.value = item.url;
}

// 预览
const toView = async (item: any) => {
  const status = await checkFileOptionsAuth('previewbutton');
  if (!status) return
  window.location.href = item.share_url;
}

// 设置下架提示语
const downTipsRef = ref();
const toDownTips = async (item: any) => {
  const status = await checkFileOptionsAuth('hintbutton');
  if (!status) return
  downTipsRef.value.openDialog(item)
}

/**
 * 加载中
 */
const loading = ref<boolean>(false);

/**
 * 文件列表处理
 */
const tableData = ref<any>([])
const tableParams = ref({ limit: 15, page: 1, title: '' })
const tableTotal = ref(0)
// 获取文件列表
const getFileList = async () => {
  loading.value = true;
  const res: any = await fileListApi(tableParams.value)
  if (res){
    loading.value = false;
  }
  // console.log(res)
  tableData.value = res.data
  tableTotal.value = res.total
}

/* 表格数据多选处理 */
const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val: any) => {
  multipleSelection.value = val
}

/**
 * 删除处理
 */
// 批量删除
const delBtn = async () => {
  const auth = await checkFileOptionsAuth('remote_destruction');
  if (!auth) return
  /* let status = await handleVipTip('remote_destruction')
  if (!status) return */
  let data = multipleSelection.value
  if (data.length == 0) {
    ElMessage.error('请选择需要删除的数据')
  } else {
    let ids = data.map((item: any) => item.id)
    // console.log(ids)
    toDelete(ids.toString())
  }
}

// 删除接口
const toDelete = async (ids: any) => {
  const auth = await checkFileOptionsAuth('remote_destruction');
  if (!auth) return

  /* let status = await handleVipTip('remote_destruction')
  if (!status) return */
  try {
    await ElMessageBox.confirm('该操作会删除当前数据', '确定要删除数据吗?', {
      confirmButtonText: '确定',
      cancelButtonText: '取消'
    })
    await fileDelApi({ id: ids })
    ElMessage.success('删除成功')
    getFileList()
  } catch (error) {

  }
}

/**
 * 上下架处理
 */
// 批量上下架
const changeStatusBtn = async (type: any) => {
  const auth = await checkFileOptionsAuth('unmountbutton');
  if (!auth) return

  let tips = '操作', status
  switch (type) {
    case 'up':
      tips = '上架'
      status = '0'
      break
    case 'down':
      tips = '下架'
      status = '1'
      break
  }
  let data = multipleSelection.value
  if (data.length == 0) {
    ElMessage.error(`请选择需要${tips}的数据`)
  } else {
    let ids = data.map((item: any) => item.id)
    // console.log(ids)
    changeStatus({ id: ids.toString(), status })
  }
}

// 改变状态
const changeStatus = async (row: any) => {
  const auth = await checkFileOptionsAuth('unmountbutton');
  if (!auth) return

  let status = row.status == '0' ? '1' : '0'
  const res = await fileStatusApi({ id: row.id, status })
  ElMessage.success('操作成功')
  await getFileList()
}

/**
 * 非会员操作提示
 */
const toSearch = async () => {
  let status = await handleVipTip('file_search', true)
  if (!status) return
  tableParams.value.page = 1
  getFileList()
}

onMounted(() => {
  getFileList()
})
</script>

<style lang="scss" scoped>
:deep(.el-form--inline) {
  .el-form-item {
    margin-bottom: 0;
    margin-right: 0.1rem;

    .el-input__wrapper {
      background-color: transparent;
    }
  }
}

.table-top {
  :deep(.el-button) {
    color: #fff;
  }
}

.table-box {
  :deep(.el-button) {
    // color: #fff;
    border-color: #dfdfdf;
  }
}

.b-text {
  font-size: 0.13rem;
  line-height: 0.15rem;
  text-decoration: underline;
  color: #FF7B6D;
}

@media screen and (max-width: 768px) {
  #file {
    height: 100%;
  }
}
</style>
