import { defineAsyncComponent } from 'vue'
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus';
import { getToken } from "@/utils/auth";
import { useUser } from '@/composable/useUser'
import { getUrlParam } from '@/utils/common'

const { checkAuth, checkAuthBack } = useUser()

import Layout from '@/layout/index.vue'

import Home from '@/views/home/index.vue'
import HomeAbout from '@/views/home/about/index.vue'
import HomeHelp from '@/views/home/help/index.vue'
import HomeContact from '@/views/home/contact/index.vue'

import Pdf from '@/views/pdf/index.vue'
import PdfResult from '@/views/pdf/result.vue'

import Login from '@/views/login/index.vue'
import Register from '@/views/login/register.vue'
import ResetPassword from '@/views/login/resetPassword.vue'

import Join from '@/views/join/index.vue'

import MemberLayout from '@/views/member/index.vue'
import MemberFile from '@/views/member/file/index.vue'
// const MemberFile = defineAsyncComponent(() => import('@/views/member/file/index.vue'))
import MemberUser from '@/views/member/user/index.vue'
import MemberMember from '@/views/member/member/index.vue'
import MemberRecycle from '@/views/member/recycle/index.vue'
import MemberNotice from '@/views/member/notice/index.vue'
import MemberTrajectory from '@/views/member/file/components/Track.vue'
import MemberCustomize from '@/views/member/customize/index.vue'

import Privilege from '@/views/member/member/Privilege.vue'
import {useUserStoreHook} from "@/store/modules/user";



const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    redirect: '/home/index',
    component: Layout,
    children: [
      {
        path: '/home/index',
        name: 'home-index',
        component: Home,
        meta: {
          title: '首页',
          showLogo: true,
          isAuth: false,
          showFooter: true
        }
      },
      {
        path: '/home/about',
        name: 'home-about',
        component: HomeAbout,
        meta: {
          showLogo: true,
          isAuth: false,
          showFooter: true
        }
      },
      {
        path: '/home/help',
        name: 'home-help',
        component: HomeHelp,
        meta: {
          showLogo: true,
          isAuth: false,
          showFooter: true
        }
      },
      {
        path: '/home/contact',
        name: 'home-contact',
        component: HomeContact,
        meta: {
          showLogo: true,
          isAuth: false,
          showFooter: true
        }
      },
    ]
  },
  {
    path: '/pdf',
    name: 'pdf',
    redirect: '/pdf/index',
    component: Layout,
    children: [
      {
        path: '/pdf/index',
        name: 'pdf-index',
        component: Pdf,
        meta: {
          isAuth: false,
          showFooter: true
        }
      },
      {
        path: '/pdf/result/:ids',
        name: 'pdf-result',
        component: PdfResult,
        meta: {
          isAuth: true
        }
      }
    ]
  },
  {
    path: '/member/join',
    name: 'join',
    component: Join,
    meta: {
      title: '会员中心',
      isAuth: true
    }
  },
  // 会员中心
  {
    path: '/member',
    name: 'member',
    redirect: '/member/index',
    component: Layout,
    children: [
      {
        path: '/member/index',
        name: 'member-index',
        redirect: '/member/file',
        component: MemberLayout,
        children: [
          {
            path: '/member/file',
            name: 'member-file',
            component: MemberFile,
            meta: {
              title: '文件管理',
              icon: 'menu01.png',
              isAuth: true,
              isVip: false,
              showArrow: true
            }
          },
          {
            path: '/member/user',
            name: 'member-user',
            component: MemberUser,
            meta: {
              title: '个人资料',
              icon: 'menu02.png',
              isAuth: true,
              isVip: false,
              showArrow: true
            }
          },
          {
            path: '/member/notice',
            name: 'member-notice',
            component: MemberNotice,
            meta: {
              title: '消息中心',
              icon: 'menu03.png',
              isAuth: true,
              isVip: false,
              showArrow: true
            }
          },
          {
            path: '/member/member',
            name: 'member-member',
            component: MemberMember,
            meta: {
              title: '会员中心',
              icon: 'menu04.png',
              isAuth: true,
              isVip: false,
              showArrow: true
            },
          },
          {
            path: '/member/recycle',
            name: 'member-recycle',
            component: MemberRecycle,
            meta: {
              title: '回收站',
              icon: 'menu05.png',
              showTopNav: true,
              isAuth: true,
              authStr: 'recycle',
              showArrow: true
            }
          },
          {
            path: '/member/customize',
            name: 'member-customize',
            component: MemberCustomize,
            meta: {
              title: '品牌定制/私有化部署',
              icon: 'menu06.png',
              showTopNav: true,
              isAuth: false,
              authStr: '',
              showArrow: false,
              showDialog: true,
              hiddenInHeader: true
            }
          },
          {
            path: '/member/trajectory',
            name: 'member-trajectory',
            component: MemberTrajectory,
            meta: {
              title: '浏览轨迹',
              icon: 'menu05.png',
              showTopNav: true,
              hidden: true,
              isAuth: true,
              authStr: 'visitor_trajectory'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    redirect: '/login/index',
    children: [
      {
        path: '/login/index',
        name: 'login-index',
        component: Login,
      },
      {
        path: '/login/register',
        name: 'login-register',
        component: Register,
      },
      {
        path: '/login/reset',
        name: 'login-reset',
        component: ResetPassword,
        meta: {
          title: '忘记密码'
        }
      }
    ]
  },


  // 会员权益
  {
    path: '/member/privilege',
    name: 'privilege',
    component: Privilege,
    meta: {
      title: '会员权益',
      icon: 'menu04.png',
      showTopNav: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(), // hash 模式
  // history: createWebHistory(process.env.BASE_URL),  // history 模式
  routes
})

declare global {
  interface Window { _hmt: any; }
}

router.beforeEach(async (to: any, from: any, next) => {
  let title = ''
  if (to.meta.title) {
    title = to.meta.title + '-';
  }
  title += '笔熊分享_为千万内容创作者提供内容保护，内容安全分享'
  document.title = title
  // console.log(to, 'to')
  if (to.matched.length === 0) {
    next('/pdf')
  } else {
    if (to.meta?.isAuth) {
      if (getToken()) {
        await useUserStoreHook().getUserInfo();
        if (to.meta?.authStr) {
          const isAuth = await checkAuthBack(to.meta?.authStr)
          if (!isAuth) {
            // 没权限
            try{
              const res = await ElMessageBox.confirm('此功能为会员功能，需要开通会员才能使用。', '提示', {
                confirmButtonText: '去开通',
                cancelButtonText: '取消',
              });
              if(res){
                next(`/member/join?redirect=${to.path}&params=${JSON.stringify(to.query ? to.query : to.params)}`)
              }else{
                if (!from.path) next('/')
                  else next(from.path)
              }
            }catch(e){
              if (!from.path) next('/')
                else next(from.path)
            }
            
            
            
            /* ElMessage({
              message: '此功能为会员功能，需要开通会员才能使用。',
              type: 'error',
              duration: 1500,
              onClose: () => {
                if (!from.path) next('/')
                else next(from.path)
              }
            }) */
          } else {
            baidu(to)
            next()
          }
        } else {
          baidu(to)
          next()
        }
      } else {
        next(`/login?redirect=${to.path}&params=${JSON.stringify(to.query ? to.query : to.params)}`);
      }
    } else {
      baidu(to)
      next()
    }
  }
})

router.afterEach((to: any, from: any) => {
  // console.log(to, 'to')
  if (replaceWechatRedirectUri()) return false;
  if (to.path.includes('home') && document.getElementsByClassName('main-container')[0]) {
    document.getElementsByClassName('main-container')[0].scrollTop = 0
  }
})
/**
 * 处理百度统计代码
 */
function baidu(to: any) {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
}

/**
 * 处理微信授权回调redirect_uri
 */
function replaceWechatRedirectUri() {
  const w = location.href.indexOf('?');
  const j = location.href.indexOf('#');
  let state:any = getUrlParam('state')
  if (w !== -1 && j > w && state) {
    let code = getUrlParam('code')
    state = filterState(state)
    let url = `${location.origin}${location.pathname}#/login/index?code=${code}`;
    if (state.redirect) url += `&redirect=${state.redirect}`;
    if (state.params) url += `&params=${state.params}`;
    console.log(url, 'url')
    if (code && state) { location.replace(url)}
    return true;
  }
}

/**
 * state 处理
*/
function filterState (state:any) {
  if (state) {
    // 替换特定字符串
    state = state.replace('{', '');
    state = state.replace('}', '');
    // 逗号拆分字符串
    state = state.split(',')
    let obj = <any>{}
    state.forEach((item:any) => {
      let arr = item.split(':')
      obj[arr[0]] = arr[1]
    });
    return obj
  } else {
    return {}
  }
}

export default router
