import { getAddressApi } from "@/api/member";
import { ElMessage } from "element-plus";
import { useNotVipTips } from "./notVipTips";

export const useAddress = () => {
  const notVipTipsInfo = useNotVipTips();
  const {notVipTips} = notVipTipsInfo;

  /**
 * 点击查看位置
 */
  const showAddress = async (row: any,  type: number, success?: (() => void)) => {
    if(!row.id || !type) return ElMessage.error('缺少必要参数。');
    const res: any = await getAddressApi({
      id: row.id,
      type
    });
    if(res.code){
      if (res.code === 400) {
        notVipTips(res.msg || res.message);
        return;
      } else {
        ElMessage.error(res.msg || res.message);
        return;
      }
    }else{
      row.addressStr = res.address;
      success && success();
    }
  }

  return { showAddress }
}