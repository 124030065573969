<script setup lang="ts">
import NoticeItem from "@/views/member/notice/components/NoticeItem.vue";
import {ref} from "vue";
import settings from "@/utils/settings";
import {queryMessageListApi} from "@/api/message";

interface props {
  tabName: string  // 选中的标签
}

const props = defineProps<props>()

console.log(props.tabName, 'tabName');

/**
 * 消息列表
 */
const noticeList = ref<any>([]);

/**
 * 翻页
 */
interface page {
  page: number,
  limit: number
}

const page = ref<page>({
  page: 1,
  limit: 10
});

const {pageSizes} = settings;

/**
 * 总条数
 */
const total = ref<number>(0);

const loading = ref(false);

/**
 * 获取消息列表
 */
const getMessageList = async () => {
  loading.value = true;
  const data: any = await queryMessageListApi({
    ...page.value,
    ...{
      type: props.tabName
    }
  })
  loading.value = false;
  total.value = data.total || 0;
  noticeList.value = data.data;
}
getMessageList();
</script>

<template>
  <div id="notice-list">
    <!-- 公告列表 -->
    <div v-if="noticeList.length" style="height: calc(100% - 0.82rem);overflow: auto">
      <notice-item v-for="item in noticeList" :key="item.id" :data="item"/>
    </div>
    <el-empty v-else style="height: calc(100% - 0.82rem)"/>

    <!-- 翻页 -->
    <div class="pagination">
      <el-pagination v-model:current-page="page.page" v-model:page-size="page.limit" :page-sizes="pageSizes" background layout="total, prev, pager, next, jumper" :total="total" :hide-on-single-page="true" @current-change="getMessageList"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
#notice-list {
  height: 100%;
  background: #fff;
  padding: 0.44rem 0.52rem;
  box-sizing: border-box;

  .pagination {
    margin-top: 0.5rem;

    :deep(.el-pagination) {
      justify-content: center;
    }
  }
}
</style>
