import request from '@/utils/request';

/**
 * 消息设置查询接口
 */
export const queryMessageSettingApi = () => {
    return request({
        url: '/msg/setlst',
        method: 'get'
    });
}

/**
 * 消息设置保存接口
 */
export const saveMessageSettingApi = (data: any) => {
    return request({
        url: '/msg/set',
        method: 'post',
        data
    });
}

/**
 * 未读提醒
 */
export const queryUnreadRemindApi = () => {
    return request({
        url: '/msg/readnum',
        method: 'get'
    });
}

/**
 * 消息列表
 */
export const queryMessageListApi = (params: any) => {
    return request({
        url: '/msg/lst',
        method: 'get',
        params
    });
}
