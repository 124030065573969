import { ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router';

export const useNotVipTips = () => {
  const router = useRouter();

  const notVipTips = (tips = '此功能为会员功能，需要开通会员才能使用。') => {
    ElMessageBox.confirm(tips ? tips : `此功能为会员功能，需要开通会员才能使用。`, '提示', {
      confirmButtonText: '去开通',
      cancelButtonText: '取消'
    }).then(() => {
      const route = router?.currentRoute.value
      router.push(`/member/join?redirect=${route.path}&params=${JSON.stringify(route.query ? route.query : route.params)}`)
    }).catch(() => {

    })
  }
  
  return {notVipTips}
}