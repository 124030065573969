<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {ArrowDown, ArrowUp} from "@element-plus/icons-vue";

interface props {
  data?: any
}

const props = withDefaults(defineProps<props>(), {
  data: {
    title: '',
    content: '',
    createtime: ''
  }
});

const content = ref({...props.data, ...{isOpen: false, show: false}});

/**
 * 是否添加隐藏类名
 */
const close = computed(() => {
  return !content.value.isOpen ? 'close' : '';
})

/**
 * 切换显示隐藏
 */
const toggleOpen = () => {
  content.value.isOpen = !content.value.isOpen;
}

/**
 * 折叠起来的最大高度
 */
const height = ref(0.2)
const maxHeight = computed(() => height.value + 'rem');

const contentRef = ref()
onMounted(() => {
  const elHeight = contentRef.value?.clientHeight;
  content.value.show = elHeight / 100 > height.value;
})
</script>

<template>
<div class="notice-item">
  <div class="notice-item-content">
    <div :class="['title',  close]">{{content.title}}</div>
    <div  :class="['content-wrap', close]">
      <div v-html="content.content" class="content" ref="contentRef" />
    </div>
  </div>

  <div class="notice-item-detail">
    <el-button link class="open-btn" @click="toggleOpen" v-if="content.show">
      <template v-if="!content.isOpen">
        <span class="text">展开</span>
        <el-icon><ArrowDown /></el-icon>
      </template>

      <template v-else>
        <span class="text">收起</span>
        <el-icon><ArrowUp /></el-icon>
      </template>
    </el-button>

    <div class="notice-item-time">{{content.createtime}}</div>
  </div>
</div>
</template>

<style scoped lang="scss">
.notice-item {
  padding: 0.3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(169, 168, 168, 0.42);
  & + .notice-item {
    margin-top: 0.11rem;
  }
  .notice-item-content{
    width: 70%;
    .title{
      font-size: 0.17rem;
      color: #353535;
    }
    .content-wrap{
      margin-top: 0.14rem;
      max-height: none;
      .content{
        font-size: 0.14rem;
        color: #7E8081;
      }
      &.close{
        max-height: v-bind(maxHeight);
        overflow: hidden;
      }
    }
  }
  .notice-item-detail{
    display: flex;
    align-items: center;
    .open-btn{
      font-size: 0.16rem;
      color: #506F9F;
      margin-right: 0.43rem;
    }
    .notice-item-time{
      font-size: 16px;
      color: #817A7A;
    }
  }
}
</style>
